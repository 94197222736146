import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "time-and-cost-integrations"
    }}>{`Time and Cost integrations`}</h1>
    <p>{`Use the Planday Time and Cost API to fetch the schedule time and cost data in a specified department and time period. To use the endpoint you’ll need to provide the `}<inlineCode parentName="p">{`departmentId`}</inlineCode>{`, and the boundaries of the time period (`}<inlineCode parentName="p">{`from`}</inlineCode>{` and `}<inlineCode parentName="p">{`to`}</inlineCode>{`), as described in the `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/schedule"
      }}>{`documentation`}</a>{`. Usually, time and cost data is related to a shift, but if there is no `}<inlineCode parentName="p">{`shiftId`}</inlineCode>{` in the response of your request, it can be because the data is related to a supplement.`}</p>
    <h3 {...{
      "id": "how-is-the-time-and-cost-calculated"
    }}>{`How is the time and cost calculated?`}</h3>
    <p>{`The data returned by the Time And Cost endpoint is a list of all shifts (except open shifts) from a given department and time period, along with their time and cost.
Below is an example to explain how the data is calculated.`}</p>
    <p>{`Suppose we have the department called `}<em parentName="p">{`Main shop`}</em>{` which has three employees assigned: Alice, Bob and Carl. They are all assigned to the same employee group: `}<em parentName="p">{`cashier`}</em>{`, and have the same salary: 10$ per hour. The break settings are defined as follows: every normal shift which is longer than 6 hours, has an automatically assigned lunch break which lasts for half an hour, and is paid. All other breaks are unpaid. Additionally, there is a `}<em parentName="p">{`late hours supplement`}</em>{` defined, and each hour worked after 6 pm is paid 50% more than standard wage.
Having defined the department settings, let's create a sample schedule for the 1st and 2nd of April, where the information for each shift means: “`}{`[start time - end time]`}{`, `}{`[Shift type]`}{`, `}{`[break]`}{`”`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Employee`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`1.04`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`2.04`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6am - 2pm, Normal shift, no breaks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2pm - 10pm, Normal shift, break 7pm - 8pm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bob`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8am - 4pm, Sick - paid 75%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8am - 4pm, Sick - paid 75%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Carl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8am - 4pm, Unpaid Vacation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8am - 4pm, Unpaid Vacation`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Now let's investigate what data the Time And Cost endpoint will return if we set the `}<inlineCode parentName="p">{`departmentId`}</inlineCode>{` to `}<em parentName="p">{`Main shop`}</em>{`'s id, and the time period to 1.04 to 2.04. First, normal shifts and shifts with the shift type setting "count hours in payroll report" enabled are included in the response. This is because there might be some shifts in the schedule that should not be included in the cost calculation and the client can configure this in Planday in shift type settings (Settings > Schedule > Shift types).
Let's assume that in our case, `}<em parentName="p">{`Normal shift`}</em>{` and `}<em parentName="p">{`Sick - paid 50%`}</em>{` types have the setting enabled, and the `}<em parentName="p">{`Unpaid vacation`}</em>{` has it disabled. Therefore, the response will include all of Alice's and Bob's shifts - 4 entries in total.`}</p>
    <pre><code parentName="pre" {...{}}>{`\`{
  "data": {
    "costs": [
      {
        "shiftId": 1,
        "duration": "0:07:30",
        "cost": 80,
        "employeeId": 1,
        "date": "2019-04-01"
      },
      {
        "shiftId": 2,
        "duration": "0:06:30",
        "cost": 85,
        "employeeId": 1,
        "date": "2019-04-02"
      },
      {
        "shiftId": 3,
        "duration": "0:08:00",
        "cost": 60,
        "employeeId": 2,
        "date": "2019-04-01"
      },
      {
        "shiftId": 4,
        "duration": "0:08:00",
        "cost": 60,
        "employeeId": 2,
        "date": "2019-04-02"
      }
    ],
    "currencySymbol": "$",
    "currencyFormatString": "{0}{1}"
  }
}\`
`}</code></pre>
    <ul>
      <li parentName="ul">{`Alice's first shift's `}<inlineCode parentName="li">{`duration`}</inlineCode>{` is equal to `}<strong parentName="li">{`7,5 hours`}</strong>{`. That's because the shift lasts for 8 hours, but has the 30 minutes lunch break automatically included.`}</li>
      <li parentName="ul">{`Alice's second shift's `}<inlineCode parentName="li">{`duration`}</inlineCode>{` equals `}<strong parentName="li">{`6,5 hours`}</strong>{` - it's the same as in the first case, but with an additional unpaid one-hour of break.`}</li>
      <li parentName="ul">{`Alice's first shift's `}<inlineCode parentName="li">{`cost`}</inlineCode>{` is equal to `}<strong parentName="li">{`80$`}</strong>{` due to 7,5 hours of work plus 0,5 hour of paid lunch break multiplied by 10$ hourly wage.`}</li>
      <li parentName="ul">{`Alice's second shift's `}<inlineCode parentName="li">{`cost`}</inlineCode>{` equals `}<strong parentName="li">{`85$`}</strong>{`. Because of the `}<em parentName="li">{`late hours supplement`}</em>{`, the wage of the 3 hours worked after 6pm equals 15$, while the wage of the first 4 hours stil equals 10$. That yields 4 times 10$ plus 3 times 15$ = 85$.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`duration`}</inlineCode>{` of both of Bob's shifts are the same, and equal to `}<strong parentName="li">{`8 hours`}</strong>{` as there is no lunch break added to this shift type.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`cost`}</inlineCode>{` of both of Bob's shifts are also the same, and equal to `}<strong parentName="li">{`60$`}</strong>{` because of the 75% wage rate associated with this shift type.`}</li>
    </ul>
    <p>{`It's worth noting that:`}</p>
    <ul>
      <li parentName="ul">{`Only normal and manual supplements will be included in the response. Daily and weekly supplements are not supported in the Time and Cost endpoint.`}</li>
      <li parentName="ul">{`Additional payroll costs set in Settings > Reports > Revenue will be included in the response`}</li>
    </ul>
    <h3 {...{
      "id": "combining-time-and-cost-with-other-apis"
    }}>{`Combining Time and Cost with other API's`}</h3>
    <p>{`The Time and Cost endpoint provides cost data, which can be used to create miscellaneous reports and statistics. As every entry from the Time and Cost endpoint response contains `}<inlineCode parentName="p">{`shiftId`}</inlineCode>{` and `}<inlineCode parentName="p">{`employeeId`}</inlineCode>{`, it's possible to query the `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/hr"
      }}>{`HR API`}</a>{` and `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/schedule"
      }}>{`Scheduling API`}</a>{`{:target="_blank"} to create a variety of useful reports. Let's look at some examples of using Time And Cost endpoint with other API's`}</p>
    <ul>
      <li parentName="ul">{`Use with `}<strong parentName="li">{`GET Employees`}</strong>{` endpoint and display a report containing employees' work time and cost.`}</li>
      <li parentName="ul">{`Use with `}<strong parentName="li">{`GET Shifts`}</strong>{` and `}<strong parentName="li">{`GET PunchClockShifts`}</strong>{` and display a comparison of employees' scheduled worktime and actual worktime.`}</li>
      <li parentName="ul">{`Use with `}<strong parentName="li">{`GET Shifts`}</strong>{` and `}<strong parentName="li">{`GET ShiftTypes`}</strong>{` and display statistics of different Shift Types' time and cost.`}</li>
      <li parentName="ul">{`Use with `}<strong parentName="li">{`GET Shifts`}</strong>{` and `}<strong parentName="li">{`GET Positions`}</strong>{` and display statistics of different positions' time and cost.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      